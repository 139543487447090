<template>
  <v-card
    class="ma-0"
    :class="{ 'card-leitura-selecionavel': cardSelecionavel }"
    :color="cor"
    :loading="carregando"
  >
    <template slot="progress">
      <v-progress-linear
        color="black"
        indeterminate
        absolute
        bottom
      />
    </template>

    <div
      height="auto"
      class="row-pointer"
      @click="$emit('click-imagem')"
    >
      <v-img
        v-if="!imagensErro.includes(leitura.leitura_id)"
        :src="'data:image/png;base64,' + leitura.imagem"
        :lazy-src="$store.state.layout.imagemLeituraCarregando"
        @error="$emit('erro-carregar-imagem')"
      />
      <v-img
        v-else
        :src="require('@/assets/imagens/imagem-padrao.png')"
        :lazy-src="$store.state.layout.imagemLeituraCarregando"
      />

      <v-chip
        class="custom-chip"
        label
        small
        pill
        color="white"
      >
        <b>{{ leitura.totalLeiturasDaPlaca || '-' }}</b>
      </v-chip>
    </div>

    <v-card-text class="pa-2 pt-0">
      <v-row
        class="fill-height mx-0 mt-n4 mb-1"
        align="end"
        justify="center"
      >
        <v-chip
          color="black"
          dark
          label
        >
          <span
            class="display-2 font-weight-black"
            :class="{ 'yellow--text': leitura.placa_modificada > 0 }"
          >
            {{ leitura.placa || '-------' }}
          </span>
        </v-chip>
      </v-row>

      <div class="caption">
        {{ leitura.data_registro | data('DD/MM/YYYY HH:mm:ss') }}
      </div>
      <div class="caption">
        {{ leitura.descricao }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {

    props: {
      leitura: {
        type: Object,
        default: () => {},
      },
      imagensErro: {
        type: Array,
        default: () => [],
      },
      carregando: {
        type: Boolean,
        default: false,
      },
      cor: {
        type: String,
        default: '',
      },
      cardSelecionavel: {
        type: Boolean,
        default: false,
      }
    },
  }
</script>

<style lang="sass">
  .row-pointer
    cursor: pointer

  #placa
    text-transform: uppercase

  .custom-chip
    position: absolute !important
    top: 0 !important
    border-radius: 0px !important
    height: 20px !important
    padding: 10px
    cursor: pointer

  .card-leitura-selecionavel
    border: dashed 1px black !important

</style>
